import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Prioritizing wellness`}</h1>
    <p>{`Throughout this year, I've had such a great time planning Wellness Wednesdays on the IHS Wellness Committee. The Wellness Committee consists of the ASB advisor, two counselors (including the wellness coordinator), 3 Youth Action Team staff members, and 3 Irvine High ASB members. I learned about the amazing resources our school provides to those in need and how crucial those services are. It's important to know that your counselors are available very often and they have a very clear schedule for your to reach out and meet with them!`}</p>
    <p>{`I think more than just meeting with counselors to talk about what's going on outside in your life, wellness is about what's going on in the inside. Staying true to yourself, making sure you're practicing good mental health techniques, and keep growing from a social/emotional aspect.`}</p>
    <p>{`Mental health and wellness is a big part of my life and I want to make it an even bigger part of Irvine High next year. Being a staff member for High School Youth Action Team, I've learned how important it is to take care of yourself and that doing service to yourself, is still technically service! Though I haven't been on the planning meetings directly, I know that the impact of YAT's weekly Wellness Wednesday is far-reaching. Whether it's a speaker talking about healthy relationships or just another peer telling you to breathe, being aware of yourself and those around you is important.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      